import * as React from 'react'
import { Button, Well } from '@pushly/aqe/lib/components'
import { EditOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { notification, Skeleton, Input } from 'antd'
import { Container } from 'typescript-ioc/es5'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { AccountDto } from '../../dtos/account.dto'
import { AccountService, AppService } from '../../services/index'
import { getClassNames } from '../../_utils/classnames'
import { SUPPORTED_BILLING_CURRENCIES } from '../../constants'
import { simpleNotification } from '../../_utils/utils'
import { validateFields } from '../../_utils/antd'
import Drawer from '../drawer/index'
import { AppState } from '../../stores/app'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js'
import { InjectedStripeForm } from '../stripe-form/injected-stripe-form'

interface IAccountBillingEditor extends FormComponentProps {
    account: AccountDto
    visible: boolean
    onClose: (e: any) => void
    onAccountUpdated: (account: Partial<AccountDto>) => void
    stripe: Stripe | null
    elements: StripeElements | null
}

interface IABEState {}

export const AccountBillingEditor = Form.create<IAccountBillingEditor>()(
    class extends React.Component<IAccountBillingEditor, IABEState> {
        public state: IABEState = {}

        protected appState: AppState
        protected appService: AppService
        protected accountService: AccountService

        public constructor(props: IAccountBillingEditor) {
            super(props)

            this.appState = Container.get(AppState)
            this.appService = Container.get(AppService)
            this.accountService = Container.get(AccountService)
        }

        public render() {
            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 6 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 18 },
                },
            }

            return (
                <Drawer
                    getContainer={this.appService.getAppContainer}
                    className={getClassNames('adf-billing-editor')}
                    title="Update Billing"
                    placement="right"
                    closable={true}
                    disableSubmit={!this.props.stripe || !this.props.elements}
                    onSubmit={this.handleSubmit}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                >
                    <Form {...formItemLayout}>
                        <Form.Item label="Contact Email" className={getClassNames('adf-billing-editor-contact-email')}>
                            {this.props.form.getFieldDecorator('contactEmail', {
                                initialValue: this.props.account.billingData.stripe_customer_email,
                                rules: [
                                    {
                                        required: true,
                                    },
                                ],
                            })(<Input size="small" autoComplete="off" />)}
                        </Form.Item>

                        <Form.Item label="Billing Credit Card" className={getClassNames('adf-billing-editor-cc')}>
                            <CardElement />
                        </Form.Item>
                    </Form>
                </Drawer>
            )
        }

        protected get account(): AccountDto {
            return this.props.account
        }

        protected get propsValue(): any {
            return this.props.account.billingData || {}
        }

        protected get formValue(): any {
            return this.props.form.getFieldsValue()
        }

        protected handleSubmit = async () => {
            try {
                const values = await validateFields(this.props.form)

                const { stripe, elements } = this.props
                if (!stripe || !elements) {
                    throw new Error('Unable to process card information at this time.')
                }

                const card = elements.getElement(CardElement)
                if (!card) {
                    throw new Error('No card information found. Please fill out the form and try again.')
                }

                const { token, error } = await stripe.createToken(card)
                if (error !== undefined) {
                    throw new Error(error.message)
                }

                if (!token || !token.card) {
                    throw {
                        card: {
                            errors: [{ message: 'Please specify a valid credit card.' }],
                        },
                    }
                }

                const changes = {
                    stripe_customer_email: values.contactEmail,
                    stripe_customer_token: token.id,
                    stripe_customer_last4: token.card.last4,
                }

                const domain = this.appState.currentDomain!
                const account = await this.accountService.manageBilling(domain.accountId, changes)

                this.props.onAccountUpdated(account)
            } catch (err) {
                simpleNotification('error', err)
            }
        }
    },
)

interface IAccountBillingWell {
    account: AccountDto
    onAccountUpdated: (account: Partial<AccountDto>) => void
    loading?: boolean
    title?: string
}

interface IABWState {
    showDrawer: boolean
}

export class AccountBillingWell extends React.Component<IAccountBillingWell, IABWState> {
    public state: IABWState = {
        showDrawer: false,
    }

    protected appService: AppService

    public constructor(props: IAccountBillingWell) {
        super(props)

        this.appService = Container.get(AppService)
    }

    public render() {
        return (
            <Well
                className="nested"
                title="Billing"
                actions={
                    <Button size="small" onClick={this.handleDrawerOpen} disabled={this.props.loading}>
                        <EditOutlined />
                        <span>Edit</span>
                    </Button>
                }
                hideFooter={true}
            >
                <Skeleton loading={this.props.loading} active={true} title={false}>
                    {!this.props.loading && (
                        <>
                            {this.renderBillingInformation()}

                            <InjectedStripeForm>
                                <ElementsConsumer>
                                    {({ stripe, elements }) => (
                                        <AccountBillingEditor
                                            visible={this.state.showDrawer}
                                            account={this.props.account}
                                            onClose={this.handleDrawerClose}
                                            onAccountUpdated={(account) => {
                                                this.props.onAccountUpdated(account)
                                                return this.handleDrawerClose()
                                            }}
                                            stripe={stripe}
                                            elements={elements}
                                        />
                                    )}
                                </ElementsConsumer>
                            </InjectedStripeForm>
                        </>
                    )}
                </Skeleton>
            </Well>
        )
    }

    protected renderBillingInformation() {
        return (
            <Well title="Billing Configuration" mode="ghost" hideFooter={true}>
                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>Contact Email</div>
                    <div className={getClassNames('form-layout-row-value')}>
                        {this.currentValues.stripe_customer_email}
                    </div>
                </div>

                {this.currentValues.stripe_customer_id && (
                    <div className={getClassNames('form-layout-row')}>
                        <div className={getClassNames('form-layout-row-label')}>Current Card</div>
                        <div className={getClassNames('form-layout-row-value')}>
                            **** **** **** {this.currentValues.stripe_customer_last4}
                        </div>
                    </div>
                )}
            </Well>
        )
    }

    protected get propsValue(): any {
        return this.props.account.billingData || {}
    }

    protected get currentValues() {
        const propsValue = this.propsValue

        const values: any = {
            type: (propsValue.type || 'INVOICE').toUpperCase(),
            currency: (propsValue.currency || 'USD').toUpperCase(),
        }

        if (values.type === 'CREDIT') {
            values.rate_type = (propsValue.rate_type || 'FLAT_RATE').toUpperCase()
            values.rate = propsValue.rate
        }

        if (propsValue.stripe_customer_id) {
            values.stripe_customer_id = propsValue.stripe_customer_id
            values.stripe_customer_email = propsValue.stripe_customer_email
            values.stripe_customer_last4 = propsValue.stripe_customer_last4
        }

        return values
    }

    protected get currentTypeIsCredit(): boolean {
        return this.currentValues.type === 'CREDIT'
    }

    protected get currentRateTypeIsFlat(): boolean {
        return this.currentValues.rate_type === 'FLAT_RATE'
    }

    protected get currentCurrencyDisplayName(): string {
        return SUPPORTED_BILLING_CURRENCIES.find((cur) => cur.value === this.currentValues.currency)!.label
    }

    protected get currentCurrencySymbol(): string {
        return SUPPORTED_BILLING_CURRENCIES.find((cur) => cur.value === this.currentValues.currency)!.symbol
    }

    protected handleDrawerOpen = async () => {
        return this.setState({ showDrawer: true })
    }

    protected handleDrawerClose = async () => {
        return this.setState({ showDrawer: false })
    }

    protected handleCopied = (field: string) => {
        notification.destroy()
        simpleNotification('success', `Your ${field} has been copied!`)
    }
}
