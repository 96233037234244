/**
 * Transforms an already cooked string to the equivalent raw string.
 *
 * @example
 * ```typescript
 * // When a string is passed, directly, via template literal to String.raw
 * // escape sequences are properly formatted.
 * String.raw`\\foo` === '\\\\foo'
 *
 * const fooStr = '\\foo'
 *
 * // String.raw does not replace escaped characters when passed via substitution
 * // because the string is already cooked.
 * String.raw`${fooStr}` -> '\\foo'
 *
 * // toRawString accepts an already cooked string and performs the same
 * // escape character replacement provided by String.raw.
 * toRawString(fooStr) -> '\\\\foo'
 * ```
 *
 * @see [MDN String.raw](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/raw)
 */
export function toRawString(str: string): string {
    const specialChars: Record<string, string> = {
        '\\': '\\\\', // backslash
        '`': '\\`', // backtick
        '${': '\\${', // template literal expression
        '\n': '\\n', // newline
        '\r': '\\r', // carriage return
        '\t': '\\t', // tab
        '\b': '\\b', // backspace
        '\f': '\\f', // form feed
    }

    return str.replace(
        new RegExp(
            Object.keys(specialChars)
                .map((char) => char.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
                .join('|'),
            'g',
        ),
        (match) => specialChars[match],
    )
}
